import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

export default function Accordion({ top, children }) {
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        padding: "10px 25px",
        backgroundColor: "#EFF1F7",
        borderRadius: "10px",
      }}
    >
      <div
        onClick={() => {
          setShow((prev) => !prev);
        }}
        className="pointer separate_horizontal" style={{alignItems: "center"}}
      >
        <div>{top}</div><div className=""><FaChevronDown size={30} /></div>
      </div>
      {show && <div style={{ padding: "20px 0px" }}>{children}</div>}
    </div>
  );
}
