import React, { useEffect, useState } from "react";
import { Navigation1 } from "../UTILITIES/Navigation";
import { useLocation } from "react-router-dom";
import { Row } from "../COMPONENTS/Row";
import { Image } from "../COMPONENTS/Image";
//
import img1 from "../IMAGES/MAIN/music-voyagers.png";
import img2 from "../IMAGES/MAIN/music-for-little-mozarts.png";
import img3 from "../IMAGES/MAIN/violin-lessons.png";
import {
  c_email,
  c_footer,
  c_nav,
  contactTemplateID,
  routes,
} from "../Constants";
import { Group } from "../COMPONENTS/Group";
import { Spacer } from "../COMPONENTS/Spacer";
import {
  function_sendBusinessEmail,
  function_sendEmail,
  screenHeight,
} from "../Global";
import { Button1, Button2 } from "../COMPONENTS/Button";
import { Textfield } from "../COMPONENTS/Textfield";
import Dropdown from "../COMPONENTS/Dropdown";
import { Textarea } from "../COMPONENTS/Textarea";
import { CheckboxList } from "../COMPONENTS/CheckboxList";
import { Clickable } from "../COMPONENTS/Clickable";

export function SummerPrograms() {
  const location = useLocation();
  //
  const [parentName, setParentName] = useState("");
  const [childAge, setChildAge] = useState("");
  const [musicExperience, setMusicExperience] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [chosenTimes, setChosenTimes] = useState([]);
  const [dob, setDOB] = useState("");
  const [summerProgram, setSummerProgram] = useState("");

  function onSubmitForm() {
    const args = {
      ParentName: parentName,
      ChildAge: childAge,
      Email: email,
      Phone: phone,
      MusicExperience: musicExperience,
      Description: description,
      ChosenTimes: chosenTimes,
      DOB: dob,
      SummerProgram: summerProgram,
    };
    if (
      parentName !== "" &&
      childAge !== "" &&
      email !== "" &&
      phone !== "" &&
      summerProgram !== "" &&
      musicExperience !== "" &&
      dob !== "" &&
      chosenTimes.length !== 0
    ) {
      function_sendBusinessEmail(
        c_email,
        "Music For Little Mozarts - Interest Entry",
        <div>
          <h1></h1>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.1)",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <p>
              <b>Parent Name:</b> {parentName}
            </p>
            <p>
              <b>Child Age:</b> {childAge}
            </p>
            <p>
              <b>DOB:</b> {dob}
            </p>
            <p>
              <b>Email: </b>
              {email}
            </p>
            <p>
              <b>Phone:</b> {phone}
            </p>
            <p>
              <b>Summer Program:</b> {summerProgram}
            </p>
            <p>
              <b>Music Experience:</b> {musicExperience}
            </p>
            <p>
              <b>Description:</b> {description}
            </p>
            <p>
              <b>Chosen Times:</b>
            </p>
            {chosenTimes.map((time, i) => {
              return <p key={i}>- {time}</p>;
            })}
          </div>
        </div>,
        contactTemplateID
      );
      function_sendEmail(
        email,
        "Music Time For Kids - Interest List",
        <div>
          <h1 className="roboto">Thank you for joining our interest list!</h1>
          <p>
            We will be in touch shortly with more details based on your form
            submission. We're excited for the musical journey ahead for your
            child!
          </p>
          <br />
          <br />
          <h1>- Music Time For Kids</h1>
          <p>Based in Morgan Hill.</p>
        </div>,
        contactTemplateID
      );

      setParentName("");
      setChildAge("");
      setMusicExperience("");
      setDescription("");
      setEmail("");
      setPhone("");
      setChosenTimes([]);
      alert("Success! We will contact you soon.");

      window.location.reload();
      window.scrollTo(0, 0);
    } else {
      alert("Please fill out required fields.");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
    if (location.hash === "#uke") {
      const element = document.getElementById("uke");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.hash === "#beat") {
      const element = document.getElementById("beat");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.hash === "#keys") {
      const element = document.getElementById("keys");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.hash === "#pickin") {
      const element = document.getElementById("pickin");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  return (
    <div className="fade_in">
      {routes.find((route) => `/${route.path}` === location.pathname).Helmet}
      {c_nav()}

      <div className="roboto full_width" style={{ marginTop: "5vh" }}>
        <Button1
          text={"Join Interest List"}
          classes={"center"}
          backgroundColor={"#117DFA"}
          borderColor={"transparent"}
          textColor={"white"}
          textSize={22}
          onClick={() => {
            const anchorElement = document.getElementById("form");
            if (anchorElement) {
              anchorElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }}
        />
      </div>

      {/* MOZARTS */}
      <Spacer height={30} />
      <div style={{ width: "90%" }} className="center">
        <Row>
          <Group height={""} classes={"roboto"}>
            <h1
              className="no_margin center_text"
              style={{ color: "#117DFA", fontSize: "4em" }}
            >
              Summer Programs
            </h1>
          </Group>
        </Row>
        <Spacer height={40} />
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(128,154,181,0.3)",
            borderRadius: 10,
          }}
        >
          <Row>
            <Group>
              <h1 className="large_text no_margin roboto">
                “Meet The Music Friends” – Summer Music Camp for Tots (Ages 4, 5
                &amp; 6)
              </h1>
              {/* <h1 className="medium_text no_margin roboto">
              5 Introductory Music Lessons For Ages 4-6
            </h1> */}
            </Group>
          </Row>
          <Row>
            <Group>
              <p className="medsmall_text roboto thin">
                This enchanting summer music camp welcomes young children to a
                world filled with musical marvels and friendly companionship.
                Inspired by the ‘Music for Little Mozarts series, our “Meet the
                Music Friends” program invites little ones to dive into the
                basics of music and piano. As the tiny campers engage with their
                new Music Friends, they’ll discover the joyous intricacies of
                melodies and harmonies in a nurturing environment.
              </p>
            </Group>
          </Row>

          <h2 className="underline roboto red">
            What will transpire, tiny campers will delight in the magical
            encounter with “Meet the Music Friends” through:
          </h2>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Interactive Exploration:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                This program offers five engaging lessons that
                blend singing, focused listening, energetic movements, and basic
                rhythm activities. Children will also get an introduction to
                playing the keyboard.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Adorable Characters:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Guided by charming characters like Mozart Mouse, Beethoven Bear,
                and Clara Schumann-Cat, young learners embark on a musical
                adventure.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Pitch Introduction:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                In the first lesson, Mozart Mouse and Beethoven Bear introduce
                the concept of pitch. Mozart Mouse, with his squeaky voice,
                prefers the piano’s higher notes, while Beethoven Bear, with
                his low-pitched growl, gravitates toward deeper notes.
              </p>
            </Group>
          </Row>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Narrative Learning:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                As children engage with the narrative, they discover the inner
                workings of music alongside their new Music Friends.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Fundamentals Exploration:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                This captivating course allows kids to explore the foundations
                of music and piano, setting the stage for further musical
                development.
              </p>
            </Group>
            <Group></Group>
          </Row>
          <Spacer height={"4vh"} />

          <Row>
            <Group classes={"roboto"}>
              <p>
                <b>Age:</b> <span className="thin">4 – 6</span>
                <br />
                <b>Music Experience:</b> <span className="thin">None</span>
                <br />
                <b>Class Size:</b> <span className="thin">8 Students</span>
                <br />
                <b>Parent Involvement:</b> <span className="thin">Yes</span>
                <br /> <b>Introductory Music Module:</b>{" "}
                <span className="thin">*5 Weeks (1 lesson per week)</span>
                <br /> <b>Class Times:</b>{" "}
                <span className="thin">age 4 - 1:00 PM or ages 5 and 6 - 3:00 PM</span>
                <br /> <b>Class Duration:</b>{" "}
                <span className="thin">50 minutes</span>
                <br /> <b>Tuition:</b>{" "}
                <span className="thin">
                  $188 (includes FREE student music book &amp; tote bag)
                </span>
                <br /> <b>Class Schedule:</b> <span className="thin">June 17th, June 24th, July 1st, July 8th, & July 15th </span>
                <a href="#form">(Join Interest List)</a>
                <br /> <b>Class Location:</b>{" "}
                <span className="thin">Avery's Avenue 17500 Monterey Rd Suite B, Morgan Hill, CA 95037</span>
              </p>
            </Group>
          </Row>
          <Row>
            <Group classes={"roboto"}>
              <p>
                <i>
                  *If you’re interested in having your child continue the Music
                  For Little Mozarts program, kindly visit our website at
                  musictimeforkids.com and sign up on our interest list for the
                  upcoming fall schedule.
                </i>
              </p>
            </Group>
          </Row>
        </div>

        {/* The Key To Having Fun: Summer Piano Camp (Ages 7 – 10) */}
        {/* <Spacer height={40} />
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(187,149,252,0.3)",
            borderRadius: 10,
          }}
        >
          <div id="keys">
            <Row>
              <Group>
                <h1 className="large_text no_margin roboto">
                  Keys To Happiness: Summer Piano Camp (Ages 7 – 10)
                </h1>
              </Group>
            </Row>
            <Row>
              <Group>
                <p className="medsmall_text roboto thin">
                  Unlock the world of music this summer with our
                  beginner-friendly piano camp! “Keys To Happiness” is a
                  specially designed summer program for students with no prior
                  music experience. Our goal is to introduce the fundamentals of
                  piano playing in an engaging, fun, and supportive environment.
                </p>
              </Group>
            </Row>
          </div>

          <h2 className="underline roboto red">
            Over the course of the camp, students will:
          </h2>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Discover the basics:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Discover the basics of piano technique, including posture,
                finger placement, and key recognition.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Learn to read music:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Learn to read music with an introduction to notes, rhythms, and
                simple melodies.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Explore different musical styles:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Explore different musical styles, from classical to
                contemporary, to find their own taste in music.
              </p>
            </Group>
          </Row>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Participate in group activities:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Participate in group activities that encourage musical
                collaboration and creativity.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Perform in a friendly recital:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Perform in a friendly recital at the end of the camp, showcasing
                their new skills to friends and family.
              </p>
            </Group>
            <Group></Group>
          </Row>
          <Row>
            <Group>
              <p className="medsmall_text roboto thin">
                Our experienced instructor is passionate about making music
                accessible to young children. They will guide each student
                through a personalized journey, ensuring that every young
                musician leaves with a sense of accomplishment and a newfound
                love for the piano.
                <br />
                <br />
                Whether your child is looking to ignite a lifelong passion or
                simply wants a fun summer activity, “Keys To Happiness” is the
                perfect starting point to embark on a musical adventure!
              </p>
            </Group>
          </Row>
          <Spacer height={"4vh"} />
          <Row>
            <Group classes={"roboto"}>
              <p>
                <b>Age:</b> <span className="thin">7 - 10</span>
                <br />
                <b>Music Experience:</b> <span className="thin">None</span>
                <br />
                <b>Class Size:</b> <span className="thin">8 Students</span>
                <br />
                <b>Parent Involvement:</b> <span className="thin">None</span>
                <br /> <b>Introductory Music Module:</b> <span className="thin">8 Weeks (1 lesson per
                week)</span>
                <br /> <b>Class Duration:</b> <span className="thin">90 minutes</span>
                <br /> <b>Tuition:</b> <span className="thin">$250 (includes FREE Student Piano
                Keyboard Kit)</span>
                <br /> <b>Class Schedule:</b> <span className="thin">TBA{" "}</span>
                <a href="#form">(Join Interest List)</a>
              </p>
            </Group>
          </Row>
        </div> */}

        {/* BEAT THE HEAT */}
        {/* <div id="beat"></div>
        <Spacer height={40} />
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(247,148,0,0.3)",
            borderRadius: 10,
          }}
        >
          <Row>
            <Group>
              <h1 className="large_text no_margin roboto">
                Beat the Heat: Summer Percussion Camp (Ages 7 – 10)
              </h1>
            </Group>
          </Row>
          <Row>
            <Group>
              <p className="medsmall_text roboto thin">
                <b>“Beat the Heat”</b> is the perfect starting point for the
                musically curious who are ready to step into the world of rhythm
                and beats. This camp is specially tailored for starter students
                with no prior music experience, offering a welcoming and
                engaging environment to learn the fundamentals of rhythm through
                a variety of percussion instruments.
              </p>
            </Group>
          </Row>

          <h2 className="underline roboto red">At our camp, beginners will:</h2>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Discover the Basics:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Learn the core concepts of rhythm, such as beat, tempo, and
                meter, in a way that’s easy to understand and fun to practice.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Experiment with Instruments:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Experience the thrill of playing different percussion
                instruments, including drums, xylophones, cymbals, and more.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Build Confidence:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Our supportive instructors will help your child gain confidence
                in their abilities, encouraging the student to try new things
                and embrace the learning process.
              </p>
            </Group>
          </Row>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Enjoy Collaborative Music-Making:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Joining other beginners in the ensemble sessions where they
                create music together, learning the value of teamwork and
                collective performance.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Celebrate Musical Progress:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Ending the camp with a sense of accomplishment by all the
                students participating in a final performance that demonstrates
                the rhythms and skills they’ve developed.
              </p>
            </Group>
            <Group></Group>
          </Row>
          <Row>
            <Group>
              <p className="medsmall_text roboto thin">
                Even without prior experience in music, “Beat the Heat” is the
                perfect place to start, filled with rhythm, laughter, and the
                joy of music. Join us for an exciting summer learning cool
                beats!
              </p>
            </Group>
          </Row>
          <Spacer height={"4vh"} />

          <Row>
            <Group classes={"roboto"}>
              <p>
                <b>Age:</b> <span className="thin">7 - 10</span>
                <br />
                <b>Music Experience:</b> <span className="thin">None</span>
                <br />
                <b>Class Size:</b> <span className="thin">8 Students</span>
                <br />
                <b>Parent Involvement:</b><span className="thin">None</span>
                <br /> <b>Introductory Music Module:</b> <span className="thin">8 Weeks (1 lesson per
                week)</span>
                <br /> <b>Class Duration:</b> <span className="thin">90 minutes</span>
                <br /> <b>Tuition:</b> <span className="thin">$250 (includes FREE Student Percussion
                Instrument Kit)</span>
                <br /> <b>Class Schedule:</b> <span className="thin">TBA{" "}</span>
                <a href="#form">(Join Interest List)</a>
              </p>
            </Group>
          </Row>
        </div> */}

        {/* Uke Can Play It Too!: Summer Ukulele Camp (Ages 7 – 10) */}
        <Spacer height={40} />
        <div id="uke"></div>
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(253,6,3,0.2)",
            borderRadius: 10,
          }}
        >
          <Row>
            <Group>
              <h1 className="large_text no_margin roboto">
                Uke Can Play It Too!: Summer Ukulele Camp (Ages 7 – 10)
              </h1>
            </Group>
          </Row>
          <Row>
            <Group>
              <p className="medsmall_text roboto thin">
                Join us this summer for a musical adventure with “Uke Can Play
                It Too!”: a beginner-friendly ukulele camp designed specifically
                for those with no prior music experience. Over the course of
                this fun-filled summer session, students will dive into the
                vibrant world of the ukulele, learning the fundamentals of this
                charming instrument.
              </p>
            </Group>
          </Row>

          <h2 className="underline roboto red">Camp Highlights:</h2>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Introduction to the Ukulele:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Get to know your ukulele, learn about its parts, and discover
                its rich history.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Basic Techniques:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Master the essentials of ukulele playing, including proper
                posture, strumming patterns, and finger placement.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Chords and Melodies:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Start playing simple songs from day one as your child builds a
                repertoire of chords and melodies.
              </p>
            </Group>
          </Row>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Group Performances:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Collaborate with fellow campers in group sessions, fostering
                teamwork and confidence.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Creative Expression:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Encourage creativity through songwriting and improvisation
                exercises.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">End-of-Camp Showcase:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Celebrate your child’s progress with a performance for family
                and friends, showcasing the skills they developed.
              </p>
            </Group>
          </Row>
          <Row>
            <Group>
              <p className="medsmall_text roboto thin">
                This camp is perfect for young children, offering a supportive
                and engaging environment where everyone can discover the joy of
                making music. No prior knowledge is required—your child only
                needs to bring their enthusiasm and be ready to strum for fun!
                <br />
                <br />
                Secure your child’s spot today to make some music this summer
                with “Uke Can Play It Too!”
              </p>
            </Group>
          </Row>
          <Spacer height={"4vh"} />
          <Row>
            <Group classes={"roboto"}>
              <p>
                <b>Age:</b> <span className="thin">7 - 10</span>
                <br />
                <b>Music Experience:</b> <span className="thin">None</span>
                <br />
                <b>Class Size:</b>
                <span className="thin">8 Students</span>
                <br />
                <b>Parent Involvement:</b> <span className="thin">None</span>
                <br /> <b>Introductory Music Module:</b>{" "}
                <span className="thin">8 Weeks (1 lesson per week)</span>
                <br /> <b>Class Time:</b>{" "}
                <span className="thin">4:00 PM</span>
                <br /> <b>Class Duration:</b>{" "}
                <span className="thin">90 minutes</span>
                <br /> <b>Tuition:</b>{" "}
                <span className="thin">
                  $250 (includes FREE Student Ukulele Kit)
                </span>
                <br /> <b>Class Schedule:</b> <span className="thin">June 17th, June 24th, July 1st, July 8th,  July 15th, July 22nd, July 29th & August 5th </span>
                <a href="#form">(Join Interest List)</a>
                <br /> <b>Class Location:</b>{" "}
                <span className="thin">Avery's Avenue 17500 Monterey Rd Suite B, Morgan Hill, CA 95037</span>
              </p>
            </Group>
          </Row>
        </div>

        {/* Pick’n It Right!: Summer Guitar Camp for Teen Beginners (Ages 11-13) */}
        <Spacer height={40} />
        <div id="pickin"></div>
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(51,87,247,0.2)",
            borderRadius: 10,
          }}
        >
          <Row>
            <Group>
              <h1 className="large_text no_margin roboto">
                Pick’n It Right!: Summer Guitar Camp for Teen Beginners (Ages
                11-13)
              </h1>
            </Group>
          </Row>
          <Row>
            <Group>
              <p className="medsmall_text roboto thin">
                “Pick’n Right is the perfect summer destination for teens ages
                11-13 looking to pick up the guitar for the first time. This
                camp is all about laying the proper foundation, ensuring that
                each strum and note is a step towards musical mastery.
              </p>
            </Group>
          </Row>

          <h2 className="underline roboto red">
            Here’s what our campers can look forward to:
          </h2>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Mastering the basics:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Mastering the basics of guitar playing in a way that’s both fun
                and informative.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Personalized attention:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Personalized attention from an experienced instructor who is
                passionate about teaching and inspiring young musicians.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">A variety of musical genres:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                A variety of musical genres to explore, ensuring a well-rounded
                introduction to the world of music.
              </p>
            </Group>
          </Row>
          <Row classes={"padding_h gap main_body_font"}>
            <Group>
              <p className="medsmall_text">Group jam sessions:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Group jam sessions that foster a sense of community and teamwork
                among campers.
              </p>
            </Group>
            <Group>
              <p className="medsmall_text">Performance opportunities:</p>
              <p style={{ fontSize: "18px", fontWeight: 200 }}>
                Performance opportunities that build confidence and showcase
                each camper’s progress.
              </p>
            </Group>
            <Group></Group>
          </Row>
          <Row>
            <Group>
              <p className="medsmall_text roboto thin">
                Set in a supportive and engaging environment, “Pick’n Right” is
                more than just a guitar camp—it’s a place where teens can
                discover their musical talents, make new friends, and enjoy the
                summer in a meaningful way.
                <br />
                <br />
                Enroll your teen in “Pick’n Right: Summer Guitar Camp for Teen
                Beginners” and watch them transform from novices to confident
                guitar players by the end of the summer!
              </p>
            </Group>
          </Row>
          <Spacer height={"4vh"} />
          <Row>
            <Group classes={"roboto"}>
              <p>
                <b>Age:</b> <span className="thin">11 - 13</span>
                <br />
                <b>Music Experience:</b> <span className="thin">None</span>
                <br />
                <b>Class Size:</b> <span className="thin">8 Students</span>
                <br />
                <b>Parent Involvement:</b> <span className="thin">None</span>
                <br /> <b>Introductory Music Module:</b>{" "}
                <span className="thin">8 Weeks (1 lesson per week)</span>
                <br /> <b>Class Time:</b>{" "}
                <span className="thin">2:00 PM</span>
                <br /> <b>Class Duration:</b>{" "}
                <span className="thin">90 minutes</span>
                <br /> <b>Tuition:</b>{" "}
                <span className="thin">
                  $250 (includes FREE Student Guitar Kit)
                </span>
                <br /> <b>Class Schedule:</b> <span className="thin">June 17th, June 24th, July 1st, July 8th,  July 15th, July 22nd, July 29th & August 5th </span>
                <a href="#form">(Join Interest List)</a>
                <br /> <b>Class Location:</b>{" "}
                <span className="thin">Avery's Avenue 17500 Monterey Rd Suite B, Morgan Hill, CA 95037</span>
              </p>
            </Group>
          </Row>
        </div>
      </div>

      <Spacer height={"8vh"} />
      <div
        id="form"
        className="roboto padding_h"
        style={{
          border: "1px solid black",
          borderRadius: "10px",
          paddingTop: "5vh",
          paddingBottom: "5vh",
          marginRight: "5vh",
          marginLeft: "5vh",
        }}
      >
        <Row>
          <Group />
          <Group>
            {/* FORM */}
            <h1 className="no_margin">Interest List Form</h1>
            <Spacer height={"20 px"} />
            <p className="no_margin">
              Parent Full Name: <span style={{ color: "red" }}>*</span>
            </p>
            <Textfield
              id={"tbParentName"}
              placeholder={"Parent Full Name"}
              setter={setParentName}
            />
            <Spacer height={"20px"} />
            <p className="no_margin">
              Email:<span style={{ color: "red" }}>*</span>
            </p>
            <Textfield
              id={"tbEmail"}
              placeholder={"Parent Email"}
              setter={setEmail}
            />
            <Spacer height={"20px"} />
            <p className="no_margin">
              Phone:<span style={{ color: "red" }}>*</span>
            </p>
            <Textfield
              id={"tbPhone"}
              placeholder={"Parent Phone"}
              setter={setPhone}
            />
            
            <Spacer height={"20px"} />
            <p className="no_margin">
              Summer Program:<span style={{ color: "red" }}>*</span>
            </p>
            <Dropdown
              id={"ddSummerProgram"}
              options={[
                "\"Meet the Music Friends\" for age 4 - 1:00pm",
                "\"Pick'n It Right\" for ages 11-13 - 2:00pm",
                "\"Meet the Music Friends\" for ages 5 and 6 - 3:00pm",
                "\"Uke Can Do It Too!\" for ages 7-10 - 4:00pm",
              ]}
              setter={setSummerProgram}
            />
            <Spacer height={"20px"} />
            <p className="no_margin">
              Music Experience:<span style={{ color: "red" }}>*</span>
            </p>
            <Dropdown
              id={"ddMuicExperience"}
              options={["None", "Some"]}
              setter={setMusicExperience}
            />
            {musicExperience === "Some" && (
              <div>
                <Spacer height={"20px"} />
                <p className="no_margin">
                  If any music experience, please give a brief description:
                </p>
                <Textarea
                  id={"taDesc"}
                  placeholder={"Type description here."}
                  classes={"roboto"}
                  setter={setDescription}
                />
              </div>
            )}
            <Spacer height={"30px"} />
            {/* SUBMIT */}

            <Button1
              text={"Submit Form"}
              backgroundColor={"#CA5455"}
              textColor={"white"}
              borderColor={"transparent"}
              onClick={() => {
                onSubmitForm();
              }}
            />
          </Group>
          <Group />
        </Row>
      </div>

      <Spacer height={"10vh"} />
      {c_footer()}
    </div>
  );
}
