import { BlogPost } from "./BLOGS/BlogPost";
import { About1, About2 } from "./PAGES/About";
import { Blog1 } from "./PAGES/Blog";
import { Contact1 } from "./PAGES/Contact";
import { Events1 } from "./PAGES/Events";
import { FAQ1 } from "./PAGES/FAQ";
import { Home1 } from "./PAGES/Home";
import { Home2 } from "./PAGES/Home";
import { Locations1 } from "./PAGES/Locations";
import { Login, OrderLogin } from "./PAGES/Login";
import { Menu1, Menu2 } from "./PAGES/Menu";
import OrderCart1 from "./PAGES/OrderCart";
import OrderConfirmation from "./PAGES/OrderConfirmation";
import { OrderOnline1 } from "./PAGES/OrderOnline";
import { Reviews1 } from "./PAGES/Reviews";
import Services1 from "./PAGES/Services";
import { Team1 } from "./PAGES/Team";
import Test from "./PAGES/Test";
import { Footer1 } from "./UTILITIES/Footer";
import { Navigation1 } from "./UTILITIES/Navigation";
import OrderOrders from "./PAGES/OrderOrders";
import Gallery1 from "./PAGES/Gallery";
import { Helmet } from "react-helmet";
import { Schedule1 } from "./PAGES/Schedule";
import { MusicMozarts } from "./PAGES/MusicMozarts";
import { MusicVoyagers } from "./PAGES/MusicVoyagers";
import { ViolinLessons } from "./PAGES/ViolinLessons";
import { SignUp } from "./PAGES/SignUp";
import { SummerPrograms } from "./PAGES/SummerPrograms";

// BUSINESS INFO
export const c_businessName = "Music Time For Kids";
export const c_desc =
  "At MUSIC TIME FOR KIDS, I believe that music is the universal language that connects hearts, sparks creativity, and enriches lives. My instructional techniques cater to students aged 3 to 18. Whether your child is a budding pianist, a violin virtuoso, or simply curious about the magic of music, I have something special for everyone.";
export const c_copyright = `Copyright 2023 ${c_businessName}. All Rights Reserved.`;
export const c_instagram = "https://www.instagram.com/musictimeforkids/";
export const c_facebook = "https://facebook.com/musictimeforkids";
export const c_yelp = "https://www.youtube.com/@musictimeforkids";
export const c_phone = "(408)763-5005";
export const c_email = "musictimeforkids@outlook.com";
export const c_address = "Avery's Avenue 17500 Monterey Rd Suite B, Morgan Hill, CA 95037";
export const c_domain = "http://localhost:3000";
//
export const stripePublishableKey =
  "pk_test_51NuJfZIDyFPNiK5CPKgovhg5fen3VM4SzxvBqdYAfwriYKzoqacsfIOiNAt5ErXss3eHYF45ak5PPFHeAD0AXit900imYxFTry";
export const serverURL = "https://thunder-easy-pick.glitch.me";
//
export const emailPublicKey = "L6fRNP99J11mfa0UD";
export const emailServiceID = "mtfk_service";
export const contactTemplateID = "contact_customer";
//
export const scheduleHours = {
  Monday: "8:00-18:00",
  Tuesday: "8:00-18:00",
  Wednesday: "8:00-18:00",
  Thursday: "8:00-18:00",
  Friday: "8:00-18:00",
  Saturday: "8:00-18:00",
  Sunday: "8:00-18:00",
};
export const appointmentTypes = [
  {
    Category: "Manicure",
    Service: "Gel Manicure",
    Duration: 30,
  },
  {
    Category: "Manicure",
    Service: "Acrylic Manicure",
    Duration: 45,
  },
];

// ROUTES
export const routes = [
  // {
  //   path: "",
  //   element: <Home1 />,
  //   text: "Home",
  //   title: "Home | Music Time For Kids",
  //   top_nav: true,
  //   hidden_nav: false,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`The music programs below are tailored to different interests and age groups. Explore the programs and
  //         find the one that resonates with your child’s passion. Get your child started on a melodic journey today!`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="music,violin,piano,mozart,kids"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  {
    path: "",
    element: <Home2 />,
    text: "Home",
    title: "Home | Music Time For Kids",
    top_nav: true,
    hidden_nav: false,
    footer_nav: true,
    Helmet: (
      <Helmet>
        <meta
          name="description"
          content={`Leading website and app development services at ${c_businessName}. Create innovative and scalable solutions with our expert team.`}
        />
        <meta
          name="keywords"
          content="website development, app development, web design, mobile app development, custom websites, responsive web, frontend, backend, SEO"
        />
        <meta name="author" content={`Innovative Internet Creations LLC`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    ),
  },
  // {
  //   path: "about",
  //   element: <About1 />,
  //   text: "About",
  //   title: "About | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Learn more about ${c_businessName} and our mission. Discover our team and the innovative solutions we provide in website and app development.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="about us, mission, team, website development, app development, innovation"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  {
    path: "about",
    element: <About2 />,
    text: "About",
    title: "About | John Ramirez, Violin Teacher",
    top_nav: true,
    hidden_nav: true,
    footer_nav: true,
    Helmet: (
      <Helmet>
        <meta
          name="description"
          content={`Learn more about ${c_businessName} and our mission. Discover our team and the innovative solutions we provide in website and app development.`}
        />
        <meta
          name="keywords"
          content="about us, mission, team, website development, app development, innovation"
        />
        <meta name="author" content={`Innovative Internet Creations LLC`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    ),
  },
  // {
  //   path: "gallery",
  //   element: <Gallery1 />,
  //   text: "Gallery",
  //   title: "Gallery | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Explore the gallery of ${c_businessName}. View our portfolio and discover a collection of visually stunning website and app development projects.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="gallery, portfolio, website design, app development, visual projects"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "classes",
  //   element: <About1 />,
  //   text: "Classes",
  //   title: "Classes | Music Time For Kids",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Discover the services offered by ${c_businessName}. From website development to app creation, our expert team provides innovative and customized solutions to meet your digital needs.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="services, website development, app development, custom solutions, digital services"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  {
    path: "summer-programs",
    element: <SummerPrograms />,
    text: "Summer Programs",
    title: "Summer Programs | Music Time For Kids",
    top_nav: true,
    hidden_nav: true,
    footer_nav: true,
    Helmet: (
      <Helmet>
        <meta
          name="description"
          content={`Discover the services offered by ${c_businessName}. From website development to app creation, our expert team provides innovative and customized solutions to meet your digital needs.`}
        />
        <meta
          name="keywords"
          content="services, website development, app development, custom solutions, digital services"
        />
        <meta name="author" content={`Innovative Internet Creations LLC`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    ),
  },
  // {
  //   path: "music-for-little-mozarts",
  //   element: <MusicMozarts />,
  //   text: "Music For Little Mozarts",
  //   title: "Music For Little Mozarts | Music Time For Kids",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Discover the services offered by ${c_businessName}. From website development to app creation, our expert team provides innovative and customized solutions to meet your digital needs.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="services, website development, app development, custom solutions, digital services"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "music-voyagers",
  //   element: <MusicVoyagers />,
  //   text: "Music Voyagers",
  //   title: "Music Voyagers | Music Time For Kids",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Discover the services offered by ${c_businessName}. From website development to app creation, our expert team provides innovative and customized solutions to meet your digital needs.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="services, website development, app development, custom solutions, digital services"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  {
    path: "violin-lessons",
    element: <ViolinLessons />,
    text: "Violin Lessons",
    title: "Violin Lessons | Music Time For Kids",
    top_nav: true,
    hidden_nav: true,
    footer_nav: true,
    Helmet: (
      <Helmet>
        <meta
          name="description"
          content={`Discover the services offered by ${c_businessName}. From website development to app creation, our expert team provides innovative and customized solutions to meet your digital needs.`}
        />
        <meta
          name="keywords"
          content="services, website development, app development, custom solutions, digital services"
        />
        <meta name="author" content={`Innovative Internet Creations LLC`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    ),
  },
  {
    path: "contact",
    element: <Contact1 />,
    text: "Contact",
    title: "Contact | The best websites in the world!",
    top_nav: true,
    hidden_nav: true,
    footer_nav: true,
    Helmet: (
      <Helmet>
        <meta
          name="description"
          content={`Contact ${c_businessName} for inquiries, collaborations, or to discuss your project. Reach out to our team for personalized assistance with website and app development.`}
        />
        <meta
          name="keywords"
          content="contact, inquiries, collaborations, website development, app development"
        />
        <meta name="author" content={`Innovative Internet Creations LLC`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    ),
  },
  // {
  //   path: "menu",
  //   element: <Menu1 />,
  //   text: "Menu",
  //   title: "Menu | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Explore the menu at ${c_businessName}. Discover a diverse range of offerings, from website development services to app creation. Choose the solutions that best suit your digital needs.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="menu, offerings, website development, app development, digital solutions"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "faq",
  //   element: <FAQ1 />,
  //   text: "FAQ",
  //   title: "FAQ | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Find answers to frequently asked questions about ${c_businessName}. Learn more about our services, policies, and commonly inquired topics in website and app development.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="FAQ, frequently asked questions, website development, app development, policies"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "locations",
  //   element: <Locations1 />,
  //   text: "Locations",
  //   title: "Locations | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Explore the locations of ${c_businessName}. Find our offices and branches, and discover the accessibility of our website and app development services in various regions.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="locations, business branches, website development, app development, accessibility"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "events",
  //   element: <Events1 />,
  //   text: "Events",
  //   title: "Events | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Discover upcoming events hosted by ${c_businessName}. Stay informed about workshops, webinars, and gatherings related to website and app development.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="events, workshops, webinars, website development, app development, gatherings"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "reviews",
  //   element: <Reviews1 />,
  //   text: "Reviews",
  //   title: "Reviews | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Read reviews and testimonials about ${c_businessName}. Explore what our clients are saying about our top-notch website and app development services.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="reviews, testimonials, website development, app development, client feedback"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "blog",
  //   element: <Blog1 />,
  //   text: "Blog",
  //   title: "Blog | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Explore insightful articles and updates on the ${c_businessName} blog. Stay informed about the latest trends in website and app development, industry news, and expert tips.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="blog, articles, website development, app development, industry news, expert tips"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "blogpost",
  //   element: <BlogPost />,
  //   text: "Blog Post",
  //   title: "Blog Post | The best websites in the world!",
  //   top_nav: false,
  //   hidden_nav: false,
  //   footer_nav: false,
  //   Helmet: (
  //     <Helmet>
  //       <meta name="description" content={``} />
  //       <meta name="keywords" content={``} />
  //       <meta name="author" content={"Innovative Internet Creations LLC"} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "team",
  //   element: <Team1 />,
  //   text: "Team",
  //   title: "Team | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Meet the talented team at ${c_businessName}. Learn about our skilled professionals in website and app development, and discover the expertise behind our innovative solutions.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="team, professionals, website development, app development, innovation, expertise"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "schedule",
  //   element: <Schedule1 />,
  //   text: "Schedule",
  //   title: "Schedule | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Meet the talented team at ${c_businessName}. Learn about our skilled professionals in website and app development, and discover the expertise behind our innovative solutions.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="team, professionals, website development, app development, innovation, expertise"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "login",
  //   element: <Login />,
  //   text: "Login",
  //   title: "Login | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Log in to ${c_businessName}. Access your account for personalized services in website and app development.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="login, account, website development, app development, personalized services"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="noindex, nofollow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "orderonline",
  //   element: <OrderOnline1 />,
  //   text: "Order Online",
  //   title: "Order Online | The best websites in the world!",
  //   top_nav: true,
  //   hidden_nav: true,
  //   footer_nav: true,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Order online from ${c_businessName}. Explore our menu and choose from a variety of website and app development services to meet your digital needs.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="order online, menu, website development, app development, digital services"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "ordercart",
  //   element: <OrderCart1 />,
  //   text: "Order Cart",
  //   title: "Order Cart | The best websites in the world!",
  //   top_nav: false,
  //   hidden_nav: false,
  //   footer_nav: false,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`View and manage your order cart at ${c_businessName}. Review the selected website and app development services before confirming your order.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="order cart, review order, website development, app development, digital services"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "orderconfirmation",
  //   element: <OrderConfirmation />,
  //   text: "Order Confirmed",
  //   title: "Order Confirmed | The best websites in the world!",
  //   top_nav: false,
  //   hidden_nav: false,
  //   footer_nav: false,
  // },
  // {
  //   path: "orderlogin",
  //   element: <OrderLogin />,
  //   text: "Order Login",
  //   title: "Order Login | The best websites in the world!",
  //   top_nav: false,
  //   hidden_nav: false,
  //   footer_nav: false,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`Log in to ${c_businessName}. Access your account for personalized services in website and app development.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="login, account, website development, app development, personalized services"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="noindex, nofollow" />
  //     </Helmet>
  //   ),
  // },
  // {
  //   path: "orders",
  //   element: <OrderOrders />,
  //   text: "Order Login",
  //   title: "Order Login | The best websites in the world!",
  //   top_nav: false,
  //   hidden_nav: false,
  //   footer_nav: false,
  //   Helmet: (
  //     <Helmet>
  //       <meta
  //         name="description"
  //         content={`View your orders at ${c_businessName}. Review and track the website and app development services you've ordered.`}
  //       />
  //       <meta
  //         name="keywords"
  //         content="orders, order history, website development, app development, order tracking"
  //       />
  //       <meta name="author" content={`Innovative Internet Creations LLC`} />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <meta name="robots" content="index, follow" />
  //     </Helmet>
  //   ),
  // },
  {
    path: "test",
    element: <Test />,
    text: "Test",
    title: "Test | The best websites in the world!",
  },
];

//
export function c_nav() {
  return <Navigation1 />;
}
export function c_footer() {
  return <Footer1 />;
}
