import React, { useEffect, useState } from "react";
import { Navigation1, Navigation2 } from "../UTILITIES/Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { c_footer, c_nav, routes } from "../Constants";
import { Row } from "../COMPONENTS/Row";
import { Group } from "../COMPONENTS/Group";
import { Image } from "../COMPONENTS/Image";
//
import img1 from "../IMAGES/MAIN/stock1.jpg";
import img2 from "../IMAGES/MAIN/music-for-little-mozarts-2.png";
import img3 from "../IMAGES/MAIN/music-voyagers-2.png";
import img4 from "../IMAGES/MAIN/private-violin-lessons-2.png";
import video1 from "../VIDEOS/music-time-for-kids-video.mp4";

import img5 from "../IMAGES/MAIN/meetfriends.png";
import img6 from "../IMAGES/MAIN/keyshappy.png";
import img7 from "../IMAGES/MAIN/beatheat.png";
import img8 from "../IMAGES/MAIN/ukecan.png";
import img9 from "../IMAGES/MAIN/pickright.png";

import { Button1, Button2 } from "../COMPONENTS/Button";
import { Block2, Block3, Block4 } from "../COMPONENTS/Blocks";
import { Spacer } from "../COMPONENTS/Spacer";
import MailingList from "./MailingList";
import { Clickable } from "../COMPONENTS/Clickable";
import { HiMiniSpeakerWave } from "react-icons/hi2";

export function Home1() {
  const navigate = useNavigate();
  const location = useLocation();
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);

  return (
    <div className="fade_in">
      {routes.find((route) => `/${route.path}` === location.pathname).Helmet}
      {c_nav()}
      {toggle && <MailingList setToggle={setToggle} />}
      <Spacer height={"2vh"} />
      <Row classes={"gap"}>
        <Group classes={"radius"} backgroundColor={"rgba(0,0,0,0)"}>
          <Block3
            heading={
              "Everything in the bagel comes around to see you on the other side."
            }
            headingSize={"6vh"}
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan. Sed ullamcorper tortor justo, vel aliquam nisi suscipit a. "
            }
            classes={"center"}
            width={"75%"}
          >
            <Button1
              onClick={() => {
                navigate("/");
              }}
              borderWidth={"2"}
              text={"Learn More"}
              textSize={"20px"}
              classes={"fit_width center main_title_font"}
              paddingH={"2em"}
              paddingV={"0.6em"}
            />
          </Block3>
        </Group>
        <Group>
          <Image image={img1} classes={"cover"} />
        </Group>
      </Row>
      <Row>
        <Group>
          <Block2
            heading={"Everything can be found in the bagels"}
            headingSize={"8vh"}
            classes={"center_text center"}
            width={"60vw"}
          />
        </Group>
      </Row>
      <Row>
        <div></div>
        <div className="padding">
          <Block4
            image={img2}
            heading={"Everything Bagel One"}
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan. Sed ullamcorper tortor justo, vel aliquam nisi suscipit a. "
            }
          />
        </div>
        <div className="padding">
          <Block4
            image={img3}
            heading={"Everything Bagel One"}
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan. Sed ullamcorper tortor justo, vel aliquam nisi suscipit a. "
            }
          />
        </div>
        <div className="padding">
          <Block4
            image={img4}
            heading={"Everything Bagel One"}
            text={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a augue et tellus varius accumsan. Integer auctor nunc dui, ut vehicula turpis pretium accumsan. Sed ullamcorper tortor justo, vel aliquam nisi suscipit a. "
            }
          />
        </div>
      </Row>
      {c_footer()}
    </div>
  );
}
export function Home2() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);

  return (
    <div className="fade_in">
      {routes.find((route) => `/${route.path}` === location.pathname).Helmet}
      {c_nav()}
      <Spacer height={"1vh"} />
      <Row>
        <Group height={"auto"} classes={"relative"}>
          <video
            id="videoViolin"
            autoPlay
            muted
            loop
            controls={true}
            style={{ width: "100%", height: "auto" }}
          >
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div style={{ position: "absolute", top: "20px", right: "20px" }}>
            <Button2
              backgroundColor={"white"}
              borderColor={"transparent"}
              paddingH={10}
              paddingV={10}
              onClick={() => {
                const videoElement = document.querySelector("#videoViolin");
                if (videoElement.muted) {
                  videoElement.muted = false;
                } else {
                  videoElement.muted = true;
                }
              }}
            >
              <HiMiniSpeakerWave color="black" size={28} />
            </Button2>
          </div>
        </Group>
      </Row>
      {/* MIDDLE */}
      <Row>
        <Group classes={"padding_v separate_vertical"}>
          <div></div>
          <Block3
            width={"70%"}
            classes={"center_text padding center"}
            heading={"Welcome to Music Time For Kids"}
            headingSize={"5vh"}
            text={
              "Music is a universal language that connects hearts and enriches lives. Our lessons cater to ages 3-18, offering something special for budding musicians and curious minds alike."
            }
            textSize={"1.3em"}
          >
            <Button1
              text={"Contact Us"}
              borderColor={"black"}
              textSize={"24px"}
              borderWidth={2}
              paddingV={"1vh"}
              classes={"center main_title_font"}
              textColor={"black"}
              onClick={() => {
                navigate("contact");
              }}
            />
          </Block3>
          <div></div>
        </Group>
      </Row>
      <Spacer height={"5vh"} />
      <Row>
        <Block2
          classes={"center full_width center_text"}
          heading={"Music Time For Kids Programs"}
          headingSize={"6vh"}
        />
      </Row>
      {/* PROGRAMS */}
      <Row classes={"gap padding_v margin_v"}>
        <Group classes={"radius"}>
          <Clickable
            onClick={() => {
              navigate("summer-programs");
            }}
          >
            <Block4
              image={img5}
              classes={"center padding_v"}
              heading={"Meet the Music Friends - Ages 4, 5, and 6"}
              headingSize={"1.6em"}
              text={
                '"Meet The Music Friends"” is a captivating program designed for young children, ages 4, 5, and 6, to explore the fundamentals of music and piano. This special course consists of five interactive lessons that combine singing, focused listening, energetic movements, basic rhythm activities, and an introduction to playing the keyboard.'
              }
              textSize={"1em"}
              width="75%"
            />
          </Clickable>
        </Group>
        <Group classes={"radius"}>
          <Clickable
            onClick={() => {
              navigate("/summer-programs#uke");
            }}
          >
            <Block4
              image={img8}
              classes={"center padding_v"}
              heading={"Uke Can Do It Too! - Ages 7 to 10"}
              headingSize={"1.6em"}
              text={
                "Join us this summer for a musical adventure with “Uke Can Play It Too!”: a beginner-friendly ukulele camp designed specifically for those with no prior music experience. Over the course of this fun-filled summer session, students will dive into the vibrant world of the ukulele, learning the fundamentals of this charming instrument."
              }
              textSize={"1em"}
              width="75%"
            />
          </Clickable>
        </Group>
        <Group classes={"radius"}>
          <Clickable
            onClick={() => {
              navigate("/summer-programs#pickin");
            }}
          >
            <Block4
              image={img9}
              classes={"center padding_v"}
              heading={"Pick'n It Right - Ages 11 to 13"}
              headingSize={"1.6em"}
              text={
                "Pick’n Right is the perfect summer destination for teens ages 11-13 looking to pick up the guitar for the first time. This camp is all about laying the proper foundation, ensuring that each strum and note is a step towards musical mastery."
              }
              textSize={"1em"}
              width="75%"
            />
          </Clickable>
        </Group>
        {/* <Group classes={"radius"}>
          <Clickable
            onClick={() => {
              navigate("/summer-programs#keys");
            }}
          >
            <Block4
              image={img6}
              classes={"center padding_v"}
              heading={"Keys To Happiness - Ages 7 to 10"}
              headingSize={"1.6em"}
              text={
                "Unlock the world of music this summer with our beginner-friendly piano camp! “Keys To Happiness” is a specially designed summer program for students with no prior music experience. Our goal is to introduce the fundamentals of piano playing in an engaging, fun, and supportive environment."
              }
              textSize={"1em"}
              width="75%"
            />
          </Clickable>
        </Group> */}
      </Row>
      {/* <Row classes={"gap padding_v margin_v"}> */}
      {/* <Group classes={"radius"}>
          <Clickable
            onClick={() => {
              navigate("/summer-programs#beat");
            }}
          >
            <Block4
              image={img7}
              classes={"center padding_v"}
              heading={"Beat The Heat - Ages 7 to 10"}
              headingSize={"1.6em"}
              text={
                "Beat the Heat” is the perfect starting point for the musically curious who are ready to step into the world of rhythm and beats. This camp is specially tailored for starter students with no prior music experience, offering a welcoming and engaging environment to learn the fundamentals of rhythm through a variety of percussion instruments."
              }
              textSize={"1em"}
              width="75%"
            />
          </Clickable>
        </Group> */}

      {/* </Row> */}
      {c_footer()}
    </div>
  );
}

// BACK UP
// export function Home2() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     document.title = routes.find(
//       (route) => `/${route.path}` === location.pathname
//     ).title;
//   }, []);

//   return (
//     <div className="fade_in">
//       {routes.find((route) => `/${route.path}` === location.pathname).Helmet}
//       {c_nav()}
//       <Spacer height={"1vh"} />
//       <Row>
//         <Group height={"auto"} classes={"relative"}>
//           <video
//             id="videoViolin"
//             autoPlay
//             muted
//             loop
//             controls={true}
//             style={{ width: "100%", height: "auto" }}
//           >
//             <source src={video1} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//           <div style={{ position: "absolute", top: "20px", right: "20px" }}>
//             <Button2
//               backgroundColor={"white"}
//               borderColor={"transparent"}
//               paddingH={10}
//               paddingV={10}
//               onClick={() => {
//                 const videoElement = document.querySelector("#videoViolin");
//                 if (videoElement.muted) {
//                   videoElement.muted = false;
//                 } else {
//                   videoElement.muted = true;
//                 }

//               }}
//             >
//               <HiMiniSpeakerWave color="black" size={28} />
//             </Button2>
//           </div>
//         </Group>
//       </Row>
//       <Row>
//         <Group classes={"padding_v separate_vertical"}>
//           <div></div>
//           <Block3
//             width={"70%"}
//             classes={"center_text padding center"}
//             heading={"Welcome to Music Time For Kids"}
//             headingSize={"5vh"}
//             text={
//               "Music is a universal language that connects hearts and enriches lives. Our lessons cater to ages 3-18, offering something special for budding musicians and curious minds alike."
//             }
//             textSize={"1.3em"}
//           >
//             <Button1
//               text={"Contact Us"}
//               borderColor={"black"}
//               textSize={"24px"}
//               borderWidth={2}
//               paddingV={"1vh"}
//               classes={"center main_title_font"}
//               textColor={"black"}
//               onClick={() => {
//                 navigate("contact");
//               }}
//             />
//           </Block3>
//           <div></div>
//         </Group>
//       </Row>
//       <Spacer height={"5vh"} />
//       <Row>
//         <Block2
//           classes={"center full_width center_text"}
//           heading={"Music Time For Kids Programs"}
//           headingSize={"6vh"}
//         />
//       </Row>
//       <Row classes={"gap padding_v margin_v"}>
//         <Group classes={"radius"}>
//           <Clickable
//             onClick={() => {
//               navigate("music-for-little-mozarts");
//             }}
//           >
//             <Block4
//               image={img2}
//               classes={"center padding_v"}
//               heading={"Music for Little Mozarts! - Ages 4, 5, 6"}
//               headingSize={"1.6em"}
//               text={
//                 "Welcome to the enchanting world of Music for Little Mozarts! This delightful series is specially designed for budding musicians aged 4 to 6 years. It’s an adaptation of the renowned Music for Little Mozarts piano curriculum, tailored to provide engaging classroom music instruction."
//               }
//               textSize={"1em"}
//               width="75%"
//             />
//           </Clickable>
//         </Group>
//         <Group classes={"radius"}>
//           <Clickable
//             onClick={() => {
//               navigate("music-voyagers");
//             }}
//           >
//             <Block4
//               image={img3}
//               classes={"center padding_v"}
//               heading={"Music Voyagers - Ages 7 - 10"}
//               headingSize={"1.6em"}
//               text={
//                 "Welcome to our captivating Musical Explorers class series, designed for curious young musicians aged 7 to 10! In this harmonious adventure, we’ll dive into melodies, rhythms, and a symphony of instruments. From toe-tapping beats to soaring notes, our explorers will discover the magic of music through movement, singing, and hands-on experiences."
//               }
//               textSize={"1em"}
//               width="75%"
//             />
//           </Clickable>
//         </Group>

//         <Group classes={"radius"}>
//           <Clickable
//             onClick={() => {
//               navigate("violin-lessons");
//             }}
//           >
//             <Block4
//               image={img4}
//               classes={"center padding_v"}
//               heading={"Private Violin Lessons - Ages 4 - 18"}
//               headingSize={"1.6em"}
//               text={
//                 "At MUSIC TIME FOR KIDS, my specialized approach to violin lessons is more than just learning to play an instrument—they’re a gateway to creativity, discipline, and soulful expression. Whether your child is a budding virtuoso or simply curious about the violin, my experienced instruction creates a nurturing environment where every bow stroke becomes a magical note."
//               }
//               textSize={"1em"}
//               width="75%"
//             />
//           </Clickable>
//         </Group>
//       </Row>
//       {c_footer()}
//     </div>
//   );
// }
