import React, { useEffect, useState } from "react";
import { Navigation1 } from "../UTILITIES/Navigation";
import { useLocation } from "react-router-dom";
import { Row } from "../COMPONENTS/Row";
import { Image } from "../COMPONENTS/Image";
//
import img1 from "../IMAGES/MAIN/about.png";
import img2 from "../IMAGES/MAIN/music-for-little-mozarts.png";
import img3 from "../IMAGES/MAIN/violin-lessons.png";
import { c_footer, c_nav, routes } from "../Constants";
import { Group } from "../COMPONENTS/Group";
import { Spacer } from "../COMPONENTS/Spacer";
import { screenHeight } from "../Global";

export function About1() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);

  return (
    <div className="fade_in">
      {routes.find((route) => `/${route.path}` === location.pathname).Helmet}
      {c_nav()}
      <Spacer height={"1vh"} />
      <Row>
        <Group height={""}>
          <Image image={img1} classes={"contain"} />
        </Group>
      </Row>
      <div style={{ width: "90%" }} className="center">
        <Row classes={"padding"}>
          <h1 className="large_text no_margin roboto">
            Journey Through Sound and Rhythm!
          </h1>
        </Row>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Music Movement and Grooves:</p>
            <p className="thin">
              Our rhythm explorers will sway, shuffle, and leap! We’ll groove to
              diverse tunes, exploring different tempos and beats. From jazzy
              swing to funky syncopation, our bodies become instruments in this
              joyful dance.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Singing and Melodic Adventures:</p>
            <p className="thin">
              Voices will rise as we sing together. Children will learn catchy
              songs, harmonize, explore vocal expression, breath control, voice
              projection, and posture. Singing fosters language development,
              creativity, and a sense of unity.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Percussion Playtime:</p>
            <p className="thin">
              Grab those drumsticks! Kids will explore percussion
              instruments—maracas, bongos, tambourines, drums cymbals and more.
              They’ll create their own rhythmic patterns, discovering the
              heartbeat of music.
            </p>
          </Group>
        </Row>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Bells and Xylophone Magic:</p>
            <p className="thin">
              The tinkling of bells and xylophone keys awaits! Children will
              explore high and low notes, play simple tunes, and unlock the
              colorful world of pitched instruments.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Piano Adventures:</p>
            <p className="thin">
              Our commencing pianists will sit at the keyboard, fingers dancing
              across the keys. We’ll introduce basic melodies, finger exercises,
              and the joy of creating music on the piano.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Recorder Quest:</p>
            <p className="thin">
              The sweet sound of the recorder beckons! Kids will learn to blow
              gently, creating melodies that float through the air. It’s a
              steppingstone to future musical endeavors.
            </p>
          </Group>
        </Row>
      </div>
      {/* MOZARTS */}
      <Spacer height={30} />
      <Row>
        <Group height={""}>
          <Image image={img2} classes={"contain"} />
        </Group>
      </Row>
      <div style={{ width: "90%" }} className="center">
        <Row classes={"padding"}>
          <h1 className="large_text no_margin roboto">
            A Magical Journey in Music!
          </h1>
        </Row>

        <Row>
          <Group>
            <p className="medsmall_text roboto thin">
              Welcome to the enchanting world of Music for Little Mozarts! This
              delightful series is specially designed for budding musicians aged
              4 to 6 years. It’s an adaptation of the renowned Music for Little
              Mozarts piano curriculum, tailored to provide engaging classroom
              music instruction.
            </p>
          </Group>
        </Row>
        <h3 className="underline roboto red">Comprehensive Approach</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Music Exploration:</p>
            <p className="thin">
              Children embark on a multifaceted musical journey that nurtures
              singing, movement, and listening skills. Simultaneously, they’re
              introduced to musical styles and concepts.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Keyboard Performance:</p>
            <p className="thin">
              Throughout the series, kids play pieces on the piano, developing
              finger dexterity and a love for creating music.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">
              Meet Beethoven Bear and Mozart Mouse™:
            </p>
            <p className="thin">
              These adorable characters set the stage for music study. Their
              whimsical adventures unfold on each page, weaving a musical tale
              that captivates young hearts.
              <br />
              <br />
              As we explore, other characters introduce students to major
              composers from different musical style periods.
            </p>
          </Group>
        </Row>
        <h3 className="underline roboto red">Key Components</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Music Lesson Book:</p>
            <p className="thin">
              The heart of the course, where new concepts are introduced,
              reinforced, and practiced. Clever lyrics and engaging stories keep
              imaginations soaring.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Music Discovery Book:</p>
            <p className="thin">
              Reinforces concepts through singing, listening, and movement
              activities. Folk songs and original music create a rich tapestry
              of sounds.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Music Workbook:</p>
            <p className="thin">
              Children color their way through pages while honing their ears. It
              aligns with the Music Lesson Book.
            </p>
          </Group>
        </Row>
        <h3 className="underline roboto red">Group Class Magic</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Ages 4 to 6:</p>
            <p className="thin">
              With this group class Music for Little Mozarts, is a great
              environment for comradery, playfulness and competitiveness within
              a small group of students. It’s perfect for fostering a love for
              music in little hearts.
            </p>
          </Group>
          <Group></Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">
          Why Choose Music for Little Mozarts
        </h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Holistic Learning:</p>
            <p className="thin">
              It’s not just about piano—it’s about singing, rhythm, movement,
              and appreciation.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Joyful Exploration:</p>
            <p className="thin">
              Kids learn while having fun, discovering the wonders of music.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Foundation for Future Musicians:</p>
            <p className="thin">
              Beethoven Bear and Mozart Mouse™ pave the way for a lifelong
              musical adventure.
            </p>
          </Group>
        </Row>
      </div>
      {/* LESSONS */}
      <Spacer height={30} />
      <Row>
        <Group height={""}>
          <Image image={img3} classes={"contain"} />
        </Group>
      </Row>
      <div style={{ width: "90%" }} className="center">
        <Row classes={"padding"}>
          <h1 className="large_text no_margin roboto">
            Violin Adventures: Unleash Your Childs Melodic Spirit!
          </h1>
        </Row>

        <Row>
          <Group>
            <p className="medsmall_text roboto thin">
              At MUSIC TIME FOR KIDS, my specialized approach to violin lessons
              is more than just learning to play an instrument—they’re a gateway
              to creativity, discipline, and soulful expression. Whether your
              child is a budding virtuoso or simply curious about the violin, my
              experienced instruction creates a nurturing environment where
              every bow stroke becomes a magical note.
            </p>
          </Group>
        </Row>
        <h3 className="underline roboto">Why Choose Violin Lessons with Me</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Individualized Approach:</p>
            <p className="thin">
              I tailor lessons to each student’s pace, interests, and goals.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Performance Opportunities:</p>
            <p className="thin">
              Recitals, chamber ensembles, and community events—my students
              shine on stage.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Music Theory Integration:</p>
            <p className="thin">
              Understanding music theory enhances the students’ playing and
              deepens their musical understanding.
            </p>
          </Group>
        </Row>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Life Skills:</p>
            <p className="thin">
              Discipline, focus, and perseverance—violin lessons nurture
              character.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">John Ramirez,</p>
            <p className="thin">
              founder and passionate violinist, envisioned a place where strings
              resonate with joy. Join me at MUSIC TIME FOR KIDS, where every bow
              stroke is a step toward harmony.
            </p>
          </Group>
          <Group></Group>
        </Row>
        {/* BEGINNER */}
        <h2 className="roboto">What to Expect as a BEGINNER violinist:</h2>
        <h3 className="underline roboto red">Proper Posture and Positioning</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Hold the Violin Correctly:</p>
            <p className="thin">
              Learn how to hold the violin with the left hand (neck) and the
              right hand (bow). Maintain a relaxed posture to avoid tension.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Chin and Shoulder Rests:</p>
            <p className="thin">
              Use a chin rest and shoulder rest to support the violin
              comfortably against your neck and shoulder.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Bow Technique</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Bow Hold:</p>
            <p className="thin">
              Understand the correct bow hold. The bow should rest lightly
              between the thumb and fingers.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Bow Strokes:</p>
            <p className="thin">
              Practice various bow strokes—long, short (staccato), and smooth
              (legato). Focus on consistent pressure and straight bowing.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">
          Finger Placement and Intonation
        </h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Fingerboard Geography:</p>
            <p className="thin">
              Learn where to place your fingers on the fingerboard to produce
              different notes.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Tuning:</p>
            <p className="thin">
              Develop an ear for tuning. Use a tuner or piano to match your
              violin’s pitch.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Basic Music Theory</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Reading Sheet Music:</p>
            <p className="thin">
              Familiarize yourself with musical notation—notes, rests, dynamics,
              and tempo markings.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Scales and Arpeggios:</p>
            <p className="thin">
              Practice major and minor scales, as well as arpeggios. They build
              finger strength and improve intonation.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Listening and Ear Training</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Listen to Violin Music:</p>
            <p className="thin">
              Immerse the student in quality violin music recordings. Pay
              attention to tone quality, phrasing, and expression.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Play by Ear:</p>
            <p className="thin">
              Playing simple tunes by ear. This enhances your musical intuition.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Practice Routine</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Consistency:</p>
            <p className="thin">
              Regular practice is crucial. Aiming for daily sessions, even if
              they’re short.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Quality over Quantity:</p>
            <p className="thin">
              Focus on quality practice—correct technique, slow practice, and
              mindful repetition.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Patience and Persistence</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Learning Curve:</p>
            <p className="thin">
              Understand that progress takes time. Be patient with yourself.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Celebrate Small Achievements:</p>
            <p className="thin">
              Celebrate each milestone, whether it’s mastering a scale or
              playing a short piece.
            </p>
          </Group>
          <Group></Group>
        </Row>
        {/* INTERMEDIATE */}
        <h2 className="roboto">What to Expect as an INTERMEDIATE violinist:</h2>
        <h3 className="underline roboto red">Advanced Techniques</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Vibrato:</p>
            <p className="thin">
              Introduction to the art of vibrato—a subtle oscillation of pitch
              that adds expressiveness to the students playing. Learning to
              practice vibrato at different speeds and widths.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Double Stops:</p>
            <p className="thin">
              Learn to play two notes simultaneously. This skill is essential
              for harmonies and rich textures.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Spiccato and Ricochet:</p>
            <p className="thin">
              Explore bowing techniques like spiccato (controlled bouncing) and
              ricochet (multiple bounces). These add flair to your playing.
            </p>
          </Group>
        </Row>
        <h3 className="underline roboto red">Intonation and Pitch Accuracy</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Refine Finger Placement:</p>
            <p className="thin">
              Continue honing your finger placement on the fingerboard. Use
              scales, arpeggios, and etudes to improve intonation.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Ear Training:</p>
            <p className="thin">
              Develop the students’ ear by playing intervals, tuning to drones,
              and recognizing pitch variations.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Artistic Interpretation</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Phrasing and Dynamics:</p>
            <p className="thin">
              Immersing the student into expressive playing. Experiment with
              crescendos, decrescendos, and subtle nuances.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Musicality:</p>
            <p className="thin">
              Understand the context of the piece. Interpret the composer’s
              intentions and convey emotions through your playing.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Repertoire Expansion</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Explore Different Styles:</p>
            <p className="thin">
              Venture beyond classical music. Explore folk tunes, jazz, or even
              contemporary compositions.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Chamber Music:</p>
            <p className="thin">
              Collaborate with other musicians. Playing in duets, trios, or
              quartets sharpens your ensemble skills.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Performance Skills</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Stage Presence:</p>
            <p className="thin">
              Work on confidence and stage presence. Perform in front of others
              regularly to overcome nervousness.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Memorization:</p>
            <p className="thin">
              Aim to memorize pieces. It enhances your connection to the music.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Technical Exercises</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Scales and Arpeggios:</p>
            <p className="thin">
              Continue practicing scales in different keys. They improve finger
              agility and strengthen your hand.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Etudes:</p>
            <p className="thin">
              Explore advanced etudes (e.g., Kreutzer, Rode, Fiorillo). They
              address specific technical challenges.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Musical Analysis</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Study Scores:</p>
            <p className="thin">
              Analyze sheet music beyond just notes. Understand harmony,
              structure, and thematic development.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Historical Context:</p>
            <p className="thin">
              Learn about the composer’s era and style. It informs your
              interpretation.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Self-Evaluation and Recording</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Record Yourself:</p>
            <p className="thin">
              Regularly record your practice sessions and performances. Listen
              critically to identify areas for improvement.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Set Goals:</p>
            <p className="thin">
              Define specific goals—whether it’s mastering a challenging passage
              or performing at a certain level.
            </p>
          </Group>
          <Group></Group>
        </Row>
        {/* ADVANCED */}
        <h2 className="roboto">What to Expect as an ADVANCED violinist:</h2>
        <h3 className="underline roboto red">Technical Mastery</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Bow Control:</p>
            <p className="thin">
              Refine your bow technique to achieve absolute control. Explore
              various bow speeds, pressure, and contact points.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Left-Hand Agility:</p>
            <p className="thin">
              Strengthen your left hand with intricate finger patterns, double
              stops, and harmonics.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Spiccato and Ricochet:</p>
            <p className="thin">
              Explore bowing techniques like spiccato (controlled bouncing) and
              ricochet (multiple bounces). These add flair to your playing.
            </p>
          </Group>
        </Row>
        <h3 className="underline roboto red">Expressive Interpretation</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Phrasing:</p>
            <p className="thin">
              Dive deep into musical phrases. Shape them with sensitivity,
              breathing life into every note.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Rubato:</p>
            <p className="thin">
              Explore flexible tempos. Use rubato to stretch time, creating
              emotional tension.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Portamento:</p>
            <p className="thin">
              Glide seamlessly between notes. Portamento adds a soulful
              connection.
            </p>
          </Group>
        </Row>
        <h3 className="underline roboto red">Advanced Repertoire</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Virtuoso Works:</p>
            <p className="thin">
              Tackle challenging pieces by Paganini, Wieniawski, and Sarasate.
              Push your technical boundaries.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Contemporary Music:</p>
            <p className="thin">
              Embrace modern compositions. Explore works by living
              composers—they’re the voice of our time.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">
          Chamber Music and Collaboration
        </h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">String Quartets:</p>
            <p className="thin">
              Dive into the heart of chamber music. Collaborate with other
              string players, weaving intricate dialogues.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Collaborate with Pianists:</p>
            <p className="thin">
              Explore violin-piano sonatas. Balance power and intimacy.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">
          Musical Analysis and Historical Context
        </h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Score Study:</p>
            <p className="thin">
              Analyze scores deeply. Understand harmonic progressions, thematic
              development, and stylistic nuances.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Period Performance:</p>
            <p className="thin">
              Explore historically informed practices. How did violinists of the
              past interpret the same pieces?
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Performance Artistry</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Stage Presence:</p>
            <p className="thin">
              Command the stage. Your presence should captivate even before the
              first note.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Audience Connection:</p>
            <p className="thin">
              Engage emotionally with your listeners. Make them feel every
              phrase.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">Teaching and Mentorship</h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Pass on Wisdom:</p>
            <p className="thin">
              Share your knowledge. Mentor young musicians. Shape the next
              generation.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Masterclasses and Workshops:</p>
            <p className="thin">
              Attend or lead masterclasses. Exchange insights with fellow
              artists.
            </p>
          </Group>
          <Group></Group>
        </Row>
        <h3 className="underline roboto red">
          Continuous Learning and Inspiration
        </h3>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Attend Concerts:</p>
            <p className="thin">
              Immerse yourself in live performances. Witness great violinists—it
              fuels your fire.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Recordings:</p>
            <p className="thin">
              Listen to historic and contemporary recordings. Learn from the
              masters.
            </p>
          </Group>
          <Group></Group>
        </Row>
      </div>

      <Spacer height={30} />
      {c_footer()}
    </div>
  );
}
export function About2() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);

  return (
    <div className="fade_in">
      {routes.find((route) => `/${route.path}` === location.pathname).Helmet}
      {c_nav()}
      <Row>
        <div style={{ padding: "2em" }}>
          <h1
            className="all_caps center_text no_margin main_title_font"
            style={{ fontSize: "4em", letterSpacing: "-1px" }}
          >
            John Ramirez
          </h1>
        </div>
      </Row>
      <Row>
        <Group classes={"center_text"}>
          <div>
            <h1 className="main_title_font large_text">Violin Teacher</h1>
            <Image image={img1} width={300} height={300} classes={"cover border"} />
          </div>
        </Group>
        <Group classes={"main_body_font"}>
          <div style={{ width: "90%" }}>
            <p className="medsmall_text">John Ramirez: A Musical Odyssey</p>
            <p className="medsmall_text thin">
              John Ramirez’s journey through the world of advanced musical
              training began at the illustrious Julliard School of Music, where
              he received a full scholarship. Under the tutelage of the
              legendary violin teacher Dorothy Delay, John’s talent flourished,
              and his passion for the violin deepened.
            </p>
            <p className="medsmall_text">Roots and Inspiration:</p>
            <p className="medsmall_text thin">
              Inspired by his father, the accomplished violinist Margarito
              Ramirez, John embarked on a career as a music educator. For the
              past 30 years, he has been instrumental in shaping San Diego
              County’s musical landscape. His contributions extend far and wide:
            </p>
            <p className="medsmall_text">
              Curriculum Architect: Shaping Musical Futures
            </p>
            <p className="medsmall_text thin">
              John’s influence resonates within esteemed institutions such as
              the Coronado School of the Arts and Canyon Crest Academy. His
              visionary approach transformed music education, leaving an
              indelible mark on the hearts and minds of countless students.
              Through his tireless dedication, he has woven a legacy that
              harmonizes creativity, discipline, and passion.
            </p>
            <p className="medsmall_text">
              Empowering Musical Journeys: Nurturing 10,000 Students
            </p>
            <p className="medsmall_text thin">
              John’s legacy reverberates across 32 private and public schools,
              where he introduced transformative music programs. From the
              delicate strings of violins to the triumphant notes of trumpets,
              he kindled the musical souls of over 10,000 students. Their
              melodies now echo through concert halls, a testament to John’s
              unwavering commitment to their artistic growth.
            </p>
            <p className="medsmall_text">
              Private Studio: A Haven for Artistic Growth
            </p>
            <p className="medsmall_text thin">
              Beyond the classroom, John’s private studio emerged as a
              sanctuary—a space where musical dreams took shape. Here, he
              meticulously mentored and sculpted future performers and teachers.
              Each note played, each technique refined, and each artistic spark
              ignited found its genesis within these walls. John’s legacy lives
              on through the countless talents who found solace and inspiration
              in this nurturing cocoon.
            </p>
            <p className="medsmall_text">
              Conductor Extraordinaire: Guiding Musical Dreams
            </p>
            <p className="medsmall_text thin">
              For a decade, John wielded his baton as the conductor of the San
              Diego Youth Symphony and Conservatory. His passion flowed through
              every note, shaping young musicians into harmonious storytellers.
              The concert halls resonated with his dedication, and the symphony
              of their collective talent echoed far beyond the stage.
            </p>
            <p className="medsmall_text">
              The New Youth Orchestra of San Diego: A Harmonious Legacy
            </p>
            <p className="medsmall_text thin">
              John’s magnum opus, the New Youth Orchestra of San Diego, stands
              as a testament to his unwavering commitment to musical excellence.
              Within its hallowed halls, four orchestras and three wind
              ensembles intertwine their melodies, creating a symphonic haven
              for over 400 students. From the soaring crescendos to the delicate
              adagios, this program transcends boundaries, nurturing young
              talents into virtuosos.
            </p>
            <p className="medsmall_text thin">
              Critics hailed it as the cutting-edge program of Southern
              California—a place where passion meets precision, and creativity
              knows no bounds. Each note played within these ensembles’ echoes
              John’s vision: to ignite musical souls, shape futures, and weave
              harmonies that resonate far beyond the concert stage.
            </p>
            <p className="medsmall_text">
              Harmonious Beginnings in Hollister: Cultivating Musical Dreams
            </p>
            <p className="medsmall_text thin">
              John Ramirez, now rooted in the serene town of Hollister, draws
              inspiration from family ties. His vision extends beyond city
              limits—Morgan Hill, Gilroy, and Hollister await, eager to embrace
              high-caliber music programs. Here, notes will dance, crescendos
              will soar, and young hearts will find their rhythm. John is
              looking forward to a symphony of new possibilities.
            </p>
          </div>
        </Group>
      </Row>

      <Spacer height={20} />
      {c_footer()}
    </div>
  );
}
