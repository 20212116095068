import React, { useEffect, useState } from "react";
import { Navigation1 } from "../UTILITIES/Navigation";
import { useLocation } from "react-router-dom";
import { Row } from "../COMPONENTS/Row";
import { Image } from "../COMPONENTS/Image";
//
import img1 from "../IMAGES/MAIN/music-voyagers.png";
import img2 from "../IMAGES/MAIN/music-for-little-mozarts.png";
import img3 from "../IMAGES/MAIN/violin-lessons.png";
import { c_footer, c_nav, routes } from "../Constants";
import { Group } from "../COMPONENTS/Group";
import { Spacer } from "../COMPONENTS/Spacer";

import { c_email, contactTemplateID } from "../Constants";
import { function_sendBusinessEmail, function_sendEmail } from "../Global";
import { Button1 } from "../COMPONENTS/Button";
import { Textfield } from "../COMPONENTS/Textfield";
import Dropdown from "../COMPONENTS/Dropdown";
import { Textarea } from "../COMPONENTS/Textarea";
import { CheckboxList } from "../COMPONENTS/CheckboxList";
import Accordion from "../COMPONENTS/Accordion";

export function ViolinLessons() {
  const location = useLocation();

  const [parentName, setParentName] = useState("");
  const [childAge, setChildAge] = useState("");
  const [musicExperience, setMusicExperience] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [chosenTimes, setChosenTimes] = useState([]);

  function onSubmitForm() {
    const args = {
      ParentName: parentName,
      ChildAge: childAge,
      Email: email,
      Phone: phone,
      MusicExperience: musicExperience,
      Description: description,
      ChosenTimes: chosenTimes,
    };

    function_sendBusinessEmail(
      c_email,
      "Private Violin Lessons - Free Lesson Entry",
      <div>
        <h1></h1>
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.1)",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <p>
            <b>Parent Name:</b> {parentName}
          </p>
          <p>
            <b>Child Age:</b> {childAge}
          </p>
          <p>
            <b>Email:</b>
            {email}
          </p>
          <p>
            <b>Phone:</b> {phone}
          </p>
          <p>
            <b>Music Experience:</b> {musicExperience}
          </p>
          <p>
            <b>Description:</b> {description}
          </p>
        </div>
      </div>,
      contactTemplateID
    );
    function_sendEmail(
      email,
      "Free Violin Lesson - Music Time For Kids",
      <div>
        <h1 className="roboto">Thank you for your your submission.</h1>
        <p>
          We will be in touch shortly with more details based on your form
          submission. We're excited for the musical journey ahead for your
          child!
        </p>
        <br />
        <br />
        <h1>- Music Time For Kids</h1>
        <p>Based in Morgan Hill.</p>
      </div>,
      contactTemplateID
    );

    setParentName("");
    setChildAge("");
    setMusicExperience("");
    setDescription("");
    setEmail("");
    setPhone("");
    setChosenTimes([]);
    alert("Success! We will contact you soon.");

    window.location.reload();
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);

  return (
    <div className="fade_in">
      {routes.find((route) => `/${route.path}` === location.pathname).Helmet}
      {c_nav()}

      <div className="roboto full_width" style={{ marginTop: "5vh" }}>
        <Button1
          text={"Free Trial Lesson"}
          classes={"center"}
          backgroundColor={"#FE0100"}
          borderColor={"transparent"}
          textColor={"white"}
          textSize={22}
          onClick={() => {
            const anchorElement = document.getElementById("form");
            if (anchorElement) {
              anchorElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }}
        />
      </div>

      {/* LESSONS */}
      <Spacer height={30} />
      <Row>
        <Group height={""}>
          <Image image={img3} classes={"contain"} />
        </Group>
      </Row>
      <div style={{ width: "90%" }} className="center">
        <Row classes={"padding"}>
          <h1 className="large_text no_margin roboto">
            Violin Adventures: Unleash Your Childs Melodic Spirit!
          </h1>
        </Row>

        <Row>
          <Group>
            <p className="medsmall_text roboto thin">
              At MUSIC TIME FOR KIDS, my specialized approach to violin lessons
              is more than just learning to play an instrument—they’re a gateway
              to creativity, discipline, and soulful expression. Whether your
              child is a budding virtuoso or simply curious about the violin, my
              experienced instruction creates a nurturing environment where
              every bow stroke becomes a magical note.
            </p>
          </Group>
        </Row>
        <h2 className="underline roboto">Why Choose Violin Lessons with Me</h2>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Individualized Approach:</p>
            <p style={{ fontSize: "18px", fontWeight: 200 }}>
              I tailor lessons to each student’s pace, interests, and goals.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Performance Opportunities:</p>
            <p style={{ fontSize: "18px", fontWeight: 200 }}>
              Recitals, chamber ensembles, and community events—my students
              shine on stage.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">Music Theory Integration:</p>
            <p style={{ fontSize: "18px", fontWeight: 200 }}>
              Understanding music theory enhances the students’ playing and
              deepens their musical understanding.
            </p>
          </Group>
        </Row>
        <Row classes={"padding_h gap main_body_font"}>
          <Group>
            <p className="medsmall_text">Life Skills:</p>
            <p style={{ fontSize: "18px", fontWeight: 200 }}>
              Discipline, focus, and perseverance—violin lessons nurture
              character.
            </p>
          </Group>
          <Group>
            <p className="medsmall_text">John Ramirez,</p>
            <p style={{ fontSize: "18px", fontWeight: 200 }}>
              founder and passionate violinist, envisioned a place where strings
              resonate with joy. Join me at MUSIC TIME FOR KIDS, where every bow
              stroke is a step toward harmony.
            </p>
          </Group>
          <Group></Group>
        </Row>
        {/* BEGINNER */}
        <h2 className="roboto">What to Expect as a BEGINNER violinist:</h2>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Proper Posture and Positioning
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Hold the Violin Correctly:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Learn how to hold the violin with the left hand (neck) and the
                  right hand (bow). Maintain a relaxed posture to avoid tension.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Chin and Shoulder Rests:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Use a chin rest and shoulder rest to support the violin
                  comfortably against your neck and shoulder.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Bow Technique</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Bow Hold:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Understand the correct bow hold. The bow should rest lightly
                  between the thumb and fingers.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Bow Strokes:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Practice various bow strokes—long, short (staccato), and
                  smooth (legato). Focus on consistent pressure and straight
                  bowing.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Finger Placement and Intonation
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Fingerboard Geography:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Learn where to place your fingers on the fingerboard to
                  produce different notes.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Tuning:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Develop an ear for tuning. Use a tuner or piano to match your
                  violin’s pitch.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Basic Music Theory</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Reading Sheet Music:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Familiarize yourself with musical notation—notes, rests,
                  dynamics, and tempo markings.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Scales and Arpeggios:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Practice major and minor scales, as well as arpeggios. They
                  build finger strength and improve intonation.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Listening and Ear Training
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Listen to Violin Music:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Immerse the student in quality violin music recordings. Pay
                  attention to tone quality, phrasing, and expression.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Play by Ear:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Playing simple tunes by ear. This enhances your musical
                  intuition.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Practice Routine</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Consistency:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Regular practice is crucial. Aiming for daily sessions, even
                  if they’re short.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Quality over Quantity:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Focus on quality practice—correct technique, slow practice,
                  and mindful repetition.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">Patience and Persistence</h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Learning Curve:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Understand that progress takes time. Be patient with yourself.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Celebrate Small Achievements:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Celebrate each milestone, whether it’s mastering a scale or
                  playing a short piece.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
        </div>
        <Spacer height={40} />
        {/* INTERMEDIATE */}
        <h2 className="roboto">What to Expect as an INTERMEDIATE violinist:</h2>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Accordion
            top={<h2 className="underline roboto red">Advanced Techniques</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Vibrato:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Introduction to the art of vibrato—a subtle oscillation of
                  pitch that adds expressiveness to the students playing.
                  Learning to practice vibrato at different speeds and widths.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Double Stops:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Learn to play two notes simultaneously. This skill is
                  essential for harmonies and rich textures.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Spiccato and Ricochet:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Explore bowing techniques like spiccato (controlled bouncing)
                  and ricochet (multiple bounces). These add flair to your
                  playing.
                </p>
              </Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Intonation and Pitch Accuracy
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Refine Finger Placement:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Continue honing your finger placement on the fingerboard. Use
                  scales, arpeggios, and etudes to improve intonation.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Ear Training:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Develop the students’ ear by playing intervals, tuning to
                  drones, and recognizing pitch variations.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">Artistic Interpretation</h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Phrasing and Dynamics:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Immersing the student into expressive playing. Experiment with
                  crescendos, decrescendos, and subtle nuances.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Musicality:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Understand the context of the piece. Interpret the composer’s
                  intentions and convey emotions through your playing.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Repertoire Expansion</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Explore Different Styles:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Venture beyond classical music. Explore folk tunes, jazz, or
                  even contemporary compositions.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Chamber Music:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Collaborate with other musicians. Playing in duets, trios, or
                  quartets sharpens your ensemble skills.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Performance Skills</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Stage Presence:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Work on confidence and stage presence. Perform in front of
                  others regularly to overcome nervousness.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Memorization:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Aim to memorize pieces. It enhances your connection to the
                  music.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Technical Exercises</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Scales and Arpeggios:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Continue practicing scales in different keys. They improve
                  finger agility and strengthen your hand.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Etudes:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Explore advanced etudes (e.g., Kreutzer, Rode, Fiorillo). They
                  address specific technical challenges.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Musical Analysis</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Study Scores:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Analyze sheet music beyond just notes. Understand harmony,
                  structure, and thematic development.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Historical Context:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Learn about the composer’s era and style. It informs your
                  interpretation.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Self-Evaluation and Recording
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Record Yourself:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Regularly record your practice sessions and performances.
                  Listen critically to identify areas for improvement.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Set Goals:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Define specific goals—whether it’s mastering a challenging
                  passage or performing at a certain level.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
        </div>
        <Spacer height={40} />
        {/* ADVANCED */}
        <h2 className="roboto">What to Expect as an ADVANCED violinist:</h2>

        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Accordion
            top={<h2 className="underline roboto red">Technical Mastery</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Bow Control:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Refine your bow technique to achieve absolute control. Explore
                  various bow speeds, pressure, and contact points.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Left-Hand Agility:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Strengthen your left hand with intricate finger patterns,
                  double stops, and harmonics.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Spiccato and Ricochet:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Explore bowing techniques like spiccato (controlled bouncing)
                  and ricochet (multiple bounces). These add flair to your
                  playing.
                </p>
              </Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Expressive Interpretation
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Phrasing:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Dive deep into musical phrases. Shape them with sensitivity,
                  breathing life into every note.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Rubato:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Explore flexible tempos. Use rubato to stretch time, creating
                  emotional tension.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Portamento:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Glide seamlessly between notes. Portamento adds a soulful
                  connection.
                </p>
              </Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Advanced Repertoire</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Virtuoso Works:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Tackle challenging pieces by Paganini, Wieniawski, and
                  Sarasate. Push your technical boundaries.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Contemporary Music:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Embrace modern compositions. Explore works by living
                  composers—they’re the voice of our time.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Chamber Music and Collaboration
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">String Quartets:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Dive into the heart of chamber music. Collaborate with other
                  string players, weaving intricate dialogues.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Collaborate with Pianists:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Explore violin-piano sonatas. Balance power and intimacy.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Musical Analysis and Historical Context
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Score Study:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Analyze scores deeply. Understand harmonic progressions,
                  thematic development, and stylistic nuances.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Period Performance:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Explore historically informed practices. How did violinists of
                  the past interpret the same pieces?
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={<h2 className="underline roboto red">Performance Artistry</h2>}
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Stage Presence:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Command the stage. Your presence should captivate even before
                  the first note.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Audience Connection:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Engage emotionally with your listeners. Make them feel every
                  phrase.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">Teaching and Mentorship</h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Pass on Wisdom:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Share your knowledge. Mentor young musicians. Shape the next
                  generation.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Masterclasses and Workshops:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Attend or lead masterclasses. Exchange insights with fellow
                  artists.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
          <Accordion
            top={
              <h2 className="underline roboto red">
                Continuous Learning and Inspiration
              </h2>
            }
          >
            <Row classes={"padding_h gap main_body_font"}>
              <Group>
                <p className="medsmall_text">Attend Concerts:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Immerse yourself in live performances. Witness great
                  violinists—it fuels your fire.
                </p>
              </Group>
              <Group>
                <p className="medsmall_text">Recordings:</p>
                <p style={{ fontSize: "18px", fontWeight: 200 }}>
                  Listen to historic and contemporary recordings. Learn from the
                  masters.
                </p>
              </Group>
              <Group></Group>
            </Row>
          </Accordion>
        </div>
      </div>
      <Spacer height={"8vh"} />
      <div
        id="form"
        className="roboto padding_h"
        style={{
          border: "1px solid black",
          borderRadius: "10px",
          paddingTop: "5vh",
          paddingBottom: "5vh",
          marginRight: "5vh",
          marginLeft: "5vh",
        }}
      >
        <Row>
          <Group />
          <Group>
            {/* FORM */}
            <h1 className="no_margin">Free Trial Lesson Form</h1>
            <Spacer height={"20 px"} />
            <p className="no_margin">
              Parent Full Name: <span style={{ color: "red" }}>*</span>
            </p>
            <Textfield
              id={"tbParentName"}
              placeholder={"Parent Full Name"}
              setter={setParentName}
            />
            <Spacer height={"20px"} />
            <p className="no_margin">
              Email:<span style={{ color: "red" }}>*</span>
            </p>
            <Textfield
              id={"tbEmail"}
              placeholder={"Parent Email"}
              setter={setEmail}
            />
            <Spacer height={"20px"} />
            <p className="no_margin">
              Phone:<span style={{ color: "red" }}>*</span>
            </p>
            <Textfield
              id={"tbPhone"}
              placeholder={"Parent Phone"}
              setter={setPhone}
            />
            <Spacer height={"20px"} />
            <p className="no_margin">
              Child's Age:<span style={{ color: "red" }}>*</span>
            </p>
            <Dropdown
              id={"ddChildAge"}
              options={Array.from({ length: 16 }, (_, i) => (i + 3).toString())}
              setter={setChildAge}
            />
            <Spacer height={"20px"} />
            <p className="no_margin">
              Music Experience:<span style={{ color: "red" }}>*</span>
            </p>
            <Dropdown
              id={"ddMuicExperience"}
              options={["Beginner","Intermediate","Advanced"]}
              setter={setMusicExperience}
            />
            {musicExperience === "Some" && (
              <div>
                <Spacer height={"20px"} />
                <p className="no_margin">
                  If any music experience, please give a brief description:
                </p>
                <Textarea
                  id={"taDesc"}
                  placeholder={"Type description here."}
                  classes={"roboto"}
                  setter={setDescription}
                />
              </div>
            )}
            <Spacer height={"30px"} />
            {/* SUBMIT */}
            {parentName !== "" &&
              childAge !== "" &&
              email !== "" &&
              phone !== "" &&
              musicExperience !== "" && (
                <Button1
                  text={"Submit Form"}
                  backgroundColor={"red"}
                  textColor={"white"}
                  borderColor={"transparent"}
                  onClick={() => {
                    onSubmitForm();
                  }}
                />
              )}
          </Group>
          <Group />
        </Row>
      </div>
      <Spacer height={"10vh"} />
      {c_footer()}
    </div>
  );
}
